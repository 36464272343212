<script setup lang="ts">
import { tokenDropFeatures } from '@/components/Home/features';
import ServiceAbout from '@/components/Service/ServiceAbout.vue';
import ServiceApproximate from '@/components/Service/ServiceApproximate.vue';
import ServiceFeatures from '@/components/Service/ServiceFeatures.vue';
import ServiceSupport from '@/components/Service/ServiceSupport.vue';
import ServiceUnfolds from '@/components/Service/ServiceUnfolds.vue';
import ServiceUseCaseCard from '@/components/Service/ServiceUseCaseCard.vue';
import ServiceVideo from '@/components/Service/ServiceVideo.vue';
import { WIZARD_SCREEN_ROUTE_NAME } from '@/router';
import { useCapabilitiesStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import KarateImage from '/public/home_banners/karate.png';
import DiamondImage from '/public/home_banners/diamond.png';

const router = useRouter();
const { canCreateCampaign } = storeToRefs(useCapabilitiesStore());

const createCampaign = async () => {
  await router.push({ name: WIZARD_SCREEN_ROUTE_NAME });
};
</script>

<template>
  <div class="homeDetailTokenDrop">
    <a-row :gutter="[16, 16]">
      <a-col :xs="24" :md="12" :lg="12" :xl="12">
        <ServiceAbout
          :title="$gettext('Blade Console Fungible Token Drop: Streamline Your Token Operations')"
          :subTitle="$gettext('Ready to start? Enjoy zero fees for your first 100 users.')"
        >
          <template #content>
            <p>
              {{
                $gettext(
                  `Harness the full potential of token circulation and engagement with Blade Console. Efficiently ` +
                    `craft and manage your token ecosystem with precision tools that simplify every step from creation ` +
                    `to distribution.`
                )
              }}
            </p>
          </template>
          <template #button>
            <a-button type="primary" size="large" @click="createCampaign()" :disabled="!canCreateCampaign">
              {{ $gettext('Get Started') }}
            </a-button>
          </template>
        </ServiceAbout>
        <ServiceFeatures :title="$gettext('Components available')" :features="tokenDropFeatures" />
      </a-col>
      <a-col :xs="24" :md="12" :lg="12" :xl="12">
        <ServiceVideo />
        <ServiceSupport />
        <ServiceApproximate
          :totalPrice="'$0.25'"
          :prices="[
            { name: $gettext('Token association/account creation'), price: '$0.05' },
            {
              name: $gettext('Blade Console services'),
              price: '$0.20',
              label: { name: $gettext('Ninja Plan'), color: 'default' },
            },
          ]"
        />
      </a-col>
    </a-row>
    <ServiceUnfolds
      :title="$gettext('Here’s how it unfolds:')"
      :steps="[
        {
          name: $gettext('Set Up'),
          description: $gettext(`Outline your token campaign.`),
        },
        {
          name: $gettext('Connect'),
          description: $gettext(`Securely link your wallet.`),
        },
        {
          name: $gettext('Distribute'),
          description: $gettext(`Generate and distribute tokens with ease.`),
        },
        {
          name: $gettext('Secure'),
          description: $gettext(`Implement advanced anti-fraud protocols.`),
        },
      ]"
    />

    <div class="homeDetailTokenDrop__useCases">{{ $gettext('Use cases') }}</div>

    <a-row :gutter="[16, 16]">
      <a-col :xs="24" :md="12" :lg="12" :xl="12">
        <ServiceUseCaseCard title="Karate Combat" :image="KarateImage">
          <p>
            {{
              $gettext(
                `Karate Combat, an innovative sports league, leveraged Blade Console Token Drop service to distribute ` +
                  `their custom tokens. Utilizing the Amount Randomizer, they introduced a thrilling element of chance to ` +
                  `their token distribution, engaging fans with the unpredictability of each token's value, enhancing ` +
                  `viewer engagement and loyalty.`
              )
            }}
          </p>
        </ServiceUseCaseCard>
      </a-col>
      <a-col :xs="24" :md="12" :lg="12" :xl="12">
        <ServiceUseCaseCard title="Diamond Standard CARATS" :image="DiamondImage">
          <p>
            {{
              $gettext(
                `Diamond Standard Co. utilized Blade Console to release their carat tokens tied to the value of real ` +
                  `diamonds. This token drop allowed investors to obtain a stake in physical diamond assets, providing a ` +
                  `unique opportunity to engage with the tangible value of precious gems in a digital format.`
              )
            }}
          </p>
        </ServiceUseCaseCard>
      </a-col>
    </a-row>
  </div>
</template>

<style lang="less">
.homeDetailTokenDrop {
  padding: 16px;

  &__useCases {
    margin: 20px 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
</style>
