<script setup lang="ts">
import { LAST_ACTIVITY_KEY, LAST_ACTIVITY_VALUE } from '@/config';
import { useUserStore } from '@/stores';
import { setCookie } from '@/utils';
import { nextTick, onBeforeMount } from 'vue';
import { getAuth } from 'firebase/auth';
import { useRouter } from 'vue-router';

const auth = getAuth();
const userStore = useUserStore();
const router = useRouter();

onBeforeMount(async () => {
  await auth.signOut();
  userStore.$reset();
  setCookie(LAST_ACTIVITY_KEY, LAST_ACTIVITY_VALUE, null);

  await nextTick(async () => {
    await router.push('/home');
  });
});
</script>

<template>
  <div></div>
</template>
