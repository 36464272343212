<script setup lang="ts">
import { combinedDropFeatures } from '@/components/Home/features';
import ServiceAbout from '@/components/Service/ServiceAbout.vue';
import ServiceApproximate from '@/components/Service/ServiceApproximate.vue';
import ServiceFeatures from '@/components/Service/ServiceFeatures.vue';
import ServiceSupport from '@/components/Service/ServiceSupport.vue';
import ServiceUnfolds from '@/components/Service/ServiceUnfolds.vue';
import ServiceVideo from '@/components/Service/ServiceVideo.vue';
import { TokenType } from '@/models';
import { WIZARD_SCREEN_ROUTE_NAME } from '@/router';
import { useCapabilitiesStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';

const router = useRouter();
const { canCreateCampaign } = storeToRefs(useCapabilitiesStore());

const createCampaign = async () => {
  await router.push({ name: WIZARD_SCREEN_ROUTE_NAME, query: { type: TokenType.Fungible } });
};
</script>

<template>
  <div class="homeDetailTokenDrop">
    <a-row :gutter="[16, 16]">
      <a-col :xs="24" :md="12" :lg="12" :xl="12">
        <ServiceAbout
          :title="
            $gettext('Blade Console Combined Token Drop: Seamlessly Distribute Both Fungible and Non-Fungible Tokens')
          "
          :subTitle="$gettext('Ready to start? Enjoy zero fees for your first 100 users.')"
        >
          <template #content>
            <p>
              {{
                $gettext(
                  'Unlock the full potential of token distribution with our Combined Token Drop feature. Effortlessly manage and distribute both fungible and non-fungible tokens in a single campaign, maximizing engagement and user interaction.'
                )
              }}
            </p>
          </template>
          <template #button>
            <a-button type="primary" size="large" @click="createCampaign()" :disabled="!canCreateCampaign">
              {{ $gettext('Get Started') }}
            </a-button>
          </template>
        </ServiceAbout>
        <ServiceFeatures :title="$gettext('Components available')" :features="combinedDropFeatures" />
      </a-col>
      <a-col :xs="24" :md="12" :lg="12" :xl="12">
        <ServiceVideo />
        <ServiceSupport />
        <ServiceApproximate
          :totalPrice="'$0.25'"
          :prices="[
            { name: $gettext('Token association/account creation'), price: '$0.05' },
            {
              name: $gettext('Blade Console services'),
              price: '$0.20',
              label: { name: $gettext('Ninja Plan'), color: 'default' },
            },
          ]"
        />
      </a-col>
    </a-row>
    <ServiceUnfolds
      :title="$gettext('Here’s how it unfolds:')"
      :steps="[
        {
          name: $gettext('Set Up'),
          description: $gettext(`Outline your token campaign.`),
        },
        {
          name: $gettext('Connect'),
          description: $gettext(`Securely link your wallet.`),
        },
        {
          name: $gettext('Configure tokens'),
          description: $gettext(`Select tokens to drop.`),
        },
        {
          name: $gettext('Secure'),
          description: $gettext(`Implement advanced anti-fraud protocols.`),
        },
        {
          name: $gettext('Distribute'),
          description: $gettext(`Generate a BladeLink.`),
        },
      ]"
    />
  </div>
</template>

<style lang="less">
.homeDetailTokenDrop {
  padding: 16px;

  &__useCases {
    margin: 20px 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
</style>
