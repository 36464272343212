import gettext from '@/gettext';
import type { Rule } from 'async-validator';
import type { Rule as AntRule } from 'ant-design-vue/es/form';

const { $gettext } = gettext;

export const spacesRule: Omit<Rule | AntRule, 'validator'> = {
  validator: (rule, value) => (!value || value[0] !== ' ' ? Promise.resolve() : Promise.reject()),
  message: $gettext('Spaces are not allowed in this field.'),
};
