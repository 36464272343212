<script setup lang="ts">
import SignUpForm from '@/components/forms/SignUpForm/SignUpForm.vue';
import type { SignUpFormModel } from '@/components/forms/SignUpForm/SignUpFormModel';
import { IDLE_LOGOUT_TIMEOUT, LAST_ACTIVITY_KEY, LAST_ACTIVITY_VALUE, PRIVACY_POLICY_LINK } from '@/config';
import { useIsValidForm } from '@/functions';
import { useSignUpMutation } from '@/graphql/generatedTypesAndHooks';
import {
  DISCLAIMER_ROUTE_NAME,
  LOGIN_SCREEN_ROUTE_NAME,
  REQUEST_RESET_PASSWORD_ROUTE_NAME,
  SIGN_UP_ROUTE_NAME,
} from '@/router';
import { setCookie } from '@/utils';
import type { MenuProps } from 'ant-design-vue';
import type { SelectInfo } from 'ant-design-vue/es/menu/src/interface';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import LoginForm from '@/components/forms/LoginForm/LoginForm.vue';
import type { LoginFormModel } from '@/components/forms/LoginForm/LoginFormModel';
import { useGettext } from 'vue3-gettext';
import { useUserStore } from '@/stores';
import { ExceptionHandler, NotificationService } from '@/services';
import { BldConsoleError } from '@/models';
import { getCurrentUser } from 'vuefire';

const route = useRoute();
const router = useRouter();
const auth = getAuth();
const { $gettext } = useGettext();
const loginProcess = ref(false);
const loading = ref(false);
const { mutate } = useSignUpMutation();
const credentials = ref<LoginFormModel>({ login: '', password: '' });
const signupCredentials = ref<SignUpFormModel>({ email: '', organizationName: '' });
const signUpFormRef = ref<InstanceType<typeof SignUpForm>>();
const isValidSignUpForm = useIsValidForm(signUpFormRef, signupCredentials);
const isDisabledFormLogin = computed(() => {
  return !credentials.value.login || !credentials.value.password;
});
const current = ref<string[]>([route.name as string]);
const menuItems = ref<MenuProps['items']>([
  {
    key: LOGIN_SCREEN_ROUTE_NAME,
    label: $gettext('Login'),
    title: $gettext('Login'),
  },
  {
    key: SIGN_UP_ROUTE_NAME,
    label: $gettext('Sign Up'),
    title: $gettext('Sign Up'),
  },
]);

onBeforeMount(async () => {
  const { currentUser } = useUserStore();

  try {
    loading.value = true;
    const fbUser = await getCurrentUser();

    if (fbUser?.email) {
      await router.push('/');
    }

    if (currentUser) {
      await redirect();
    }
  } finally {
    loading.value = false;
  }
});

const redirect = async () => {
  const to = route.query.redirect && typeof route.query.redirect === 'string' ? route.query.redirect : '/';
  await router.push(to);
};

const login = async () => {
  try {
    loginProcess.value = true;
    await signInWithEmailAndPassword(auth, credentials.value.login, credentials.value.password);
    setCookie(LAST_ACTIVITY_KEY, LAST_ACTIVITY_VALUE, IDLE_LOGOUT_TIMEOUT);
    await redirect();
  } catch (e) {
    NotificationService.error($gettext('Oops! Authorization failed. Please try again.'));
    ExceptionHandler.handleError(new BldConsoleError(e, 'Login failed.'));
  } finally {
    loginProcess.value = false;
  }
};

const signup = async () => {
  try {
    loginProcess.value = true;

    await mutate({
      data: {
        email: signupCredentials.value.email?.trim(),
        organizationName: signupCredentials.value.organizationName?.trim(),
      },
    });

    signupCredentials.value.email = '';
    signupCredentials.value.organizationName = '';
    NotificationService.success($gettext('Signup successful. Please check your email for further instructions.'));

    await router.push({ name: LOGIN_SCREEN_ROUTE_NAME, force: true });
  } catch (e) {
    // maybe we should handle this error in a different way like code
    if (e.message === 'User already exists') {
      NotificationService.error($gettext('The user already exists, you can try logging in with this email.'));

      return;
    }

    if (e.message === 'Organization already exists') {
      NotificationService.error($gettext('The organization already exists, you can try change name.'));

      return;
    }

    NotificationService.error($gettext('Oops! Signup failed. Please try again.'));
    ExceptionHandler.handleError(new BldConsoleError(e, 'Signup failed.'));
  } finally {
    loginProcess.value = false;
  }
};

const menuSelect = (e: SelectInfo) => {
  if (e.key === LOGIN_SCREEN_ROUTE_NAME) {
    router.push({ name: LOGIN_SCREEN_ROUTE_NAME });
  } else if (e.key === SIGN_UP_ROUTE_NAME) {
    router.push({ name: SIGN_UP_ROUTE_NAME });
  }
};

watch(
  () => route.name,
  (v) => {
    current.value = [v as string];
  },
  { immediate: true }
);
</script>

<template>
  <a-row class="loginView" v-if="!loading" align="middle" justify="center" :gutter="[0, 40]" :key="route.name">
    <a-col :xs="2" :sm="4" :lg="2" :xl="1" :xxl="2"></a-col>
    <a-col :xs="20" :sm="16" :lg="6" :xl="6" :xxl="4">
      <a-menu v-model:selectedKeys="current" mode="horizontal" :items="menuItems" @select="menuSelect" />
      <div v-if="current[0] === LOGIN_SCREEN_ROUTE_NAME">
        <LoginForm @enter="login" v-model:value="credentials" />
        <div class="text-right loginView__formDescription">
          <router-link :to="{ name: REQUEST_RESET_PASSWORD_ROUTE_NAME }">
            <a-button type="link">{{ $gettext('Forgot your password?') }}</a-button>
          </router-link>
        </div>
        <a-button block :disabled="isDisabledFormLogin" :loading="loginProcess" @click="login" type="primary">
          {{ $gettext('Login') }}
        </a-button>
      </div>
      <div v-if="current[0] === SIGN_UP_ROUTE_NAME">
        <SignUpForm ref="signUpFormRef" @enter="signup" v-model:value="signupCredentials" />
        <div class="loginView__description">
          <span class="description">{{ $gettext('We will send you an email with login instructions.') }}</span>
        </div>
        <a-button block :disabled="!isValidSignUpForm" :loading="loginProcess" @click="signup" type="primary">
          {{ $gettext('Sign Up') }}
        </a-button>
      </div>
      <br />
      <br />
      <div class="loginView__policy">
        <div class="text-center">
          {{ $gettext('By clicking Login I acknowledge that I have read the') }}
        </div>
        <div class="text-center">
          <a @click="router.push({ name: DISCLAIMER_ROUTE_NAME })" target="_blank">
            {{ $gettext('Disclaimers') }}
          </a>
          {{ $gettext('and agree to the') }}
          <a :href="PRIVACY_POLICY_LINK" target="_blank">{{ $gettext('Privacy Policy') }}</a>
        </div>
      </div>
    </a-col>
    <a-col :xs="2" :sm="4" :lg="2" :xl="1" :xxl="2"></a-col>

    <a-col :xs="2" :sm="4" :lg="2" :xl="1" :xxl="2"></a-col>
    <a-col :xs="20" :sm="16" :lg="10" :xl="14" :xxl="12">
      <div class="f-size-30 f-weight-500 lh-40">{{ $gettext('Welcome to Blade Console!') }}</div>
      <div>{{ $gettext('Your new command center for token management and distribution.') }}</div>
      <ul class="loginView__list">
        <li>
          <span class="f-weight-500">{{ $gettext('Secure Login:') }}</span>
          {{
            $gettext(
              'Enter your details to access the full suite of Blade Console features, crafted for precision and ease.'
            )
          }}
        </li>
        <li>
          <span class="f-weight-500">{{ $gettext('Comprehensive Control:') }}</span>
          {{ $gettext('Harness the power to manage and distribute tokens efficiently, all from one robust platform.') }}
        </li>
        <li>
          <span class="f-weight-500">{{ $gettext('Collaborative Flexibility:') }}</span>
          {{
            $gettext(
              'Utilize our multi-signature wallet feature, enabling your team to jointly oversee token accounts securely.'
            )
          }}
        </li>
        <li>
          <span class="f-weight-500">{{ $gettext('Fresh Innovation:') }}</span>
          {{
            $gettext(
              'We’re proud to present the newly launched Blade Console, designed to elevate your digital asset strategy.'
            )
          }}
        </li>
      </ul>
      <div class="f-size-20 f-weight-500">{{ $gettext('Log in to start shaping your token landscape.') }}</div>
    </a-col>
    <a-col :xs="2" :sm="4" :lg="2" :xl="1" :xxl="2"></a-col>
  </a-row>
</template>

<style lang="less" scoped>
@import '@/assets/variables';

.loginView {
  position: fixed;
  height: calc(100vh - 64px * 2);
  width: 100%;
  align-content: center;

  &__description,
  &__formDescription {
    position: relative;
    top: -13px;
    right: -10px;

    button {
      font-weight: 600;
    }
  }

  &__description {
    padding: 5px;
  }

  & :deep(.ant-menu) {
    background: transparent;
    margin-bottom: 20px;
  }

  @media (max-width: @screen-lg - 1px) {
    position: static;
    height: auto;
    flex-flow: wrap-reverse;
  }

  &__policy {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &__list {
    padding-left: 1em;
    margin: 24px 0;
    list-style-type: '◆';

    li {
      padding: 10px 0 10px 0.3em;
    }

    ::marker {
      padding-right: 12px;
    }
  }
}
</style>
